import {ProductOptionGroup} from "@app/models/product-option-group.model";
import {ProductOption} from "@app/models/product-option.model";
import {Product} from "@app/models/product.model";

export class Cart {
  id: string;
  items: CartItem[] = [];

  private constructor(id: string, items: CartItem[]) {
    this.id = id;
    this.items = items;
  }

  get total(): number{
    return this.items.reduce((sum, item) => sum + item.total, 0);
  }

  static createFromObject(cartObj: any) : Cart {
    let cartItems: CartItem[] = [];
    if(cartObj) {
      cartObj.items.forEach((item: any) => {
        cartItems.push(CartItem.createFromObject(item));
      });
    }
    return new Cart(cartObj.id, cartItems);
  }

  static create(id: string, cartItems: CartItem[]) {
    return new Cart(id, cartItems);
  }
}

export class CartItem {
  id: string;
  cartId: string;
  productId: string;
  name: string;
  image: string;
  quantity: number;
  onSale: boolean;
  price: number;
  optionGroups?: ProductOptionGroup[];

  private constructor(
    id: string,
    cartId: string,
    productId: string,
    name: string,
    image: string,
    quantity: number,
    onSale: boolean,
    price: number,
    optionGroups?: ProductOptionGroup[],
  ){
    this.id = id;
    this.cartId = cartId;
    this.productId = productId;
    this.name = name;
    this.image = image;
    this.quantity = quantity;
    this.onSale = onSale;
    this.price = price;
    this.optionGroups = optionGroups;
  }

  get subTotal(): number{
    return this.price + this.getOptionTotalPrice();
  }

  get total(): number{
    const optionTotalPrice = this.getOptionTotalPrice();
    return this.quantity * (this.price + optionTotalPrice);
  }

  get options(): ProductOption[]{
    let options: ProductOption[] = [];
    for (let group of this.optionGroups) {
      for(let option of group.options){
        if(option.isChecked) {
          options.push(option);
        }
      }
    }
    return options;
  }

  getOptionTotalPrice(): number{
    if(this.optionGroups) {
      return this.optionGroups.reduce((sum, group) => sum +
        group.options.reduce((sum, option) => sum + (option.isChecked ? option.total : 0), 0)
        , 0);
    }
    return 0;
  }

  static createFromProduct(product: Product) {
    const quantity = 1;
    const price = product?.onSale ? product?.salePrice : product?.price;
    return new CartItem("", "", product.id, product.name,
        product.imageSmall ?? product.imageMedium ?? product.image, quantity,
        product.onSale, price, product.optionGroups);

  }

  static createFromObject(cartItemObj: any): CartItem {
    return new CartItem(cartItemObj.id, cartItemObj.cartId, cartItemObj.productId, cartItemObj.name, cartItemObj.image,
        cartItemObj.quantity, cartItemObj.onSale, cartItemObj.price, cartItemObj.optionGroups)
  }

    static createWithIdAndCartId(id: string, cartId: string, cartItem: CartItem): CartItem {
        return new CartItem(id, cartId, cartItem.productId, cartItem.name, cartItem.image,
            cartItem.quantity, cartItem.onSale, cartItem.price, cartItem.optionGroups)
    }

    static createWithQuantity(quantity: number, cartItem: CartItem): CartItem {
        return new CartItem(cartItem.id, cartItem.cartId, cartItem.productId, cartItem.name, cartItem.image,
            quantity, cartItem.onSale, cartItem.price, cartItem.optionGroups)
    }

    static incrementQuantity(cartItem: CartItem): CartItem {
        const newQuantity = cartItem.quantity + 1
        return new CartItem(cartItem.id, cartItem.cartId, cartItem.productId, cartItem.name, cartItem.image,
            newQuantity, cartItem.onSale, cartItem.price, cartItem.optionGroups)
    }

    static decrementQuantity(cartItem: CartItem): CartItem {
        const newQuantity = cartItem.quantity - 1
        return new CartItem(cartItem.id, cartItem.cartId, cartItem.productId, cartItem.name, cartItem.image,
            newQuantity, cartItem.onSale, cartItem.price, cartItem.optionGroups)
    }

    toData(): any {
      return {
          cartId: this.cartId,
          productId: this.productId,
          name: this.name,
          image: this.image,
          quantity: this.quantity,
          onSale: this.onSale,
          price: this.price,
          total: this.total,
          optionTotalPrice: this.getOptionTotalPrice()
      }
    }
}
