export const environment = {
  production: true,
  name: 'prod',
  useEmulators: false,
  firebase: {
    config: {
      apiKey: 'AIzaSyDn-JcZRvUcyIEhRXhvwnukW7DQVdX4h48',
      authDomain: 'store-block-web-prod.firebaseapp.com',
      projectId: 'store-block-prod',
      storageBucket: 'store-block-prod.appspot.com',
      messagingSenderId: '953935075909',
      appId: '1:953935075909:web:2ae3b4ec55a8643307212c',
      measurementId: 'G-P72X8WYJ4Z'
    },
    verificationRedirectUrl: 'https://storebloc.com/'
  },
  region: 'asia-southeast1',
  recaptchaKey: '6LeWG3UoAAAAABlWuxAUGMc8IYe3Lq6RUxfZWNqY',
  swiftPayRedirectUrl: 'https://api.pay.live.swiftpay.ph',
  buildNumber: '20240914.3',
  appUrl: 'https://app.storebloc.com/',
  apiUrl: 'https://asia-southeast1-store-block-prod.cloudfunctions.net/api'
};
